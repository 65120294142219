.about_container {
  font-size: 1.5vw;
  background-color: rgba(0, 0, 0, 0.797);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 8rem;
  height: 100vh;
}

.right_item img {
  border-radius: 50%;
  border: solid 0.5rem white;
  width: 17rem;
  height: 16rem;
}

.left_item {
  margin-right: 8rem;
}

.highlight {
  font-weight: bold;
}

@media (max-width: 890px) {
  .about_container {
    flex-direction: column;
    padding: 1rem;
    height: auto;
  }

  .left_item {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .right_item img {
    width: 12rem;
    height: 12rem;
  }
}
