.skills {
  padding: 3rem 3rem;
  background-color: rgb(236, 235, 232);
}

.grid_container {
  display: grid;
  grid-template-columns: 40% 40%;
  grid-template-rows: repeat(2, 40%);
  column-gap: 20%;
  row-gap: 20%;
  font-size: 1.2vw;
}

.span {
  font-size: 3.5rem;
  color: #007bff;
}

@media (max-width: 880px) {
  .grid_container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    column-gap: 0;
    row-gap: 2rem;
  }
}
