.works {
  padding: 3rem 3rem;
  background-color: rgb(36, 36, 36);
  color: white;
}

.works h2 {
  text-align: center;
  font-size: 3rem;
  text-transform: uppercase;
  margin-bottom: 5rem;
}

.works p {
  font-size: 1.2rem;
  line-height: 1.5rem;
}
