.header_flex_container {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  align-items: center;
  font-size: 1.5rem;
  transition: background-color 0.3s ease;
}

.header_flex_container a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.fixed_header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: transparent;
  z-index: 1000;
  color: white;
}

.scrolled {
  background-color: white;
  color: black;
  font-size: 1.2rem;
}

.item_container {
  display: flex;
  list-style: none;
}

.items {
  margin-right: 2rem;
}

.left {
  margin-left: 4rem;
}

.right {
  margin-right: 4rem;
}
