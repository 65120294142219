.image_container {
  position: relative;
}

.wallpaper {
  width: 100%;
  height: 100vh;
  display: block;
}

.text {
  transform: translate(-50%, -50%);
  position: absolute;
  text-align: center;
  font-size: 3vw;
  color: white;
  left: 50%;
  top: 80%;
}
.textP {
  transform: translate(-50%, -50%);
  position: absolute;
  font-size: 2vw;
  font-style: italic;
  text-align: center;
  left: 50%;
  top: 93%;
  color: white;
}

.paul {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 45%;
  left: 50%;
  border-radius: 50%;
  border: solid 0.5rem white;
  width: 17rem;
  height: 16rem;
}
