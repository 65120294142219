.footer_container {
  display: flex;
  justify-content: space-evenly;
  text-transform: uppercase;
  align-items: center;
  font-size: 1.5rem;
  transition: background-color 0.3s ease;
  padding: 1.5rem;
  background-color: black;
  color: white;
}

.right_footer {
  display: flex;
  list-style: none;
}
