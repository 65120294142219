.work {
  margin-bottom: 7rem;
}

.work img {
  height: 80vh;
  border: 2px solid white;
  width: 80vw;
}

.work h1 {
  font-size: 2rem;
}

.work p {
  font-size: 1rem;
}
