.form_container {
  display: flex;
  justify-content: center;
  padding: 6rem;
  background-color: #f7f7f7;
}

.span_center {
  text-align: center;
}
.form_container h2 {
  font-size: 3rem;
  color: #ff6200;
}

.form_container p {
  font-size: 1.2rem;
}

.form_container button {
  align-items: center;
}

.form_element {
  margin-bottom: 2rem;
}

label {
  display: block;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  height: 2rem;
  padding-left: 1rem;
  box-sizing: border-box;
}

textarea {
  padding-top: 0.5rem;
}

.button {
  width: 15rem;
  height: 3rem;
  text-align: center;
  font-size: 1.4rem;
  align-items: center;
  color: black;
  background-color: #ff6200;
  border: 1px solid #ff6200;
  align-items: center;
  align-self: center;
  text-transform: uppercase;
  border-width: 2px;
  border-color: black;
  text-align: center;
}

.button:hover {
  cursor: pointer;
  background-color: #ff9e62;
  color: black;
}
